import { ref, computed, ComputedRef, Ref } from 'vue';

interface FormData {
  [key: string]: string | boolean;
}

interface Options {
  optionalFields?: string[];
}

interface ValidationErrors {
  [key: string]: boolean;
}

interface IUseInputValidationReturn {
  isValid: ComputedRef<boolean>;
  validationErrors: Ref<ValidationErrors>;
}

type IUseInputValidation = (formData: FormData, options?: Options ) => IUseInputValidationReturn;

const useInputValidation: IUseInputValidation = function (formData: FormData, options?: Options) {
  const validationErrors = ref<ValidationErrors>({});

  const validationInputErrors = computed(() => {
    return Object.values(validationErrors.value).some(error => error);
  });

  const isValid = computed(() => {
    const isNotFullFormData = Object.keys(formData).some((key) => {
      if (options?.optionalFields && options.optionalFields.includes(key)) {
        return false; // Ignore optional fields
      }
      return !formData[key];
    });
    return !isNotFullFormData && !validationInputErrors.value;
  });

  return {
    isValid,
    validationErrors,
  };
};

export default useInputValidation;