<template>
  <div v-if="policyPath" class="agreement" v-bind="$attrs">
    <div class="agreement-body">
      <BaseCheckbox :checked="modelValue" @change="onChangeCheckbox" />
      <div class="agreement-link">
        <i18n-t keypath="agreement" scope="global" tag="p" class="text-14-400">
          <template #link>
            <LocLink :to="policyPath" target="_blank" class="text-14-400">
              {{ $t("agreement_link") }}
            </LocLink>
          </template>
        </i18n-t>
      </div>
    </div>
    <div v-if="error" class="agreement-error">
      <transition name="input_error">
        <span class="input-error__text"> {{ error }} </span>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { NavItem } from "@nuxt/content";
import { PropType } from "vue";
import BaseCheckbox from "~/components/input/BaseCheckbox.vue";
import LocLink from "~/components/lang/LocLink.vue";

export default {
  components: { LocLink, BaseCheckbox },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    error: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  async setup(props, { emit }) {
    const { locale } = useI18n();

    const isShowDocumentModal = ref<boolean>(false);

    function openDocumentModal() {
      isShowDocumentModal.value = true;
    }

    function onChangeCheckbox() {
      emit("update:modelValue", !props.modelValue);
    }

    const policyQuery = queryContent(`${locale.value}/policy`);
    const policyNavigation = ref<NavItem[] | null>(null);
    const policyNavigationChildren = computed(
      () => policyNavigation.value?.[0]?.children?.[0]?.children
    );

    const policyPath = computed(() => policyNavigationChildren.value?.[
        policyNavigationChildren.value?.length - 1
      ]?._path)

    onMounted(async () => {
      policyNavigation.value = await fetchContentNavigation(policyQuery);
    });

    return {
      isShowDocumentModal,
      openDocumentModal,
      onChangeCheckbox,
      policyPath,
    };
  },
};
</script>
