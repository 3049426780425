import { ERROR_MESSAGE } from "~/utils/messages";

const EMAIL_SYMBOLS_COMMON_REGEXP = /^[\w\-.@]*$/;
const EMAIL_SYMBOLS_DUPLICATE_REGEXP = /^[^@]*$|(@{2,}|\.{2,})/g;

export default function emailValidator(inputVal: string): null | string {
  if (inputVal.length > 0) {
    if (!EMAIL_SYMBOLS_COMMON_REGEXP.test(inputVal)) {
      return ERROR_MESSAGE.invalidCharacters;
    }

    if (inputVal.match(EMAIL_SYMBOLS_DUPLICATE_REGEXP)) {
      return ERROR_MESSAGE.invalidFormat;
    }
  }

  return null;
}
