import emailValidator from "./email";
import numberValidator from "./number";
import phoneValidator from "./phone";

const UNAVAILABLE_SYMBOLS = ["(", ")", "-"];

export default {
  email: emailValidator,
  number: numberValidator,
  phone: {
    phoneValidator: phoneValidator,
    unavailableSymbols: UNAVAILABLE_SYMBOLS,
  },
};
