import URlS from "~/api/urls";
import {
  FormAskQuestionFields,
  FormBecomePartnerFields,
  FormGetOfferFields,
} from "~/types/form.interface";

export const askQuestion = async (
  fields: FormAskQuestionFields
): Promise<void> => {
  const url = URlS.form.askQuestion;
  return await $fetch(url, {
    method: "POST",
    body: fields,
  });
};

export const getOffer = async (fields: FormGetOfferFields): Promise<void> => {
  const url = URlS.form.getOffer;
  return await $fetch(url, {
    method: "POST",
    body: fields,
  });
};

export const sendBecomePartnerFields = async (
  fields: FormBecomePartnerFields
): Promise<void> => {
  const url = URlS.form.becomePartner;
  return await $fetch(url, {
    method: "POST",
    body: fields,
  });
};
