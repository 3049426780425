import { ERROR_MESSAGE } from "~/utils/messages";

const NUMBER_REGEXP = /^\d+$/;

export default function numberValidator(
  inputVal: number | string
): null | string {
  const val = inputVal.toString();

  if (val.length > 0 && !NUMBER_REGEXP.test(val)) {
    return ERROR_MESSAGE.invalidCharacters;
  }

  return null;
}
